@import "@sitestyles";

.main_header_left {
  flex: 1;
  display: flex;
  align-items: center;
}

.main_header_left_item {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $primaryColor;
  cursor: pointer;
}

.main_header_left_item:first-child {
  margin-right: 70px;
}

.main_header_logo {
  flex: 1;
}

.main_header_logo img {
  display: block;
  margin: 0 auto;
}

.main_header_login {
  flex: 1;
  display: flex;
}

.main_header_login_btn {
  margin-left: auto;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $unknownSenSmBLPaAColor;
  cursor: pointer;
  padding: 5px 0;
}

.main_header_login_btn img {
  margin-right: 15px;
}

.first_block_content_wrap {
  position: relative;
}

.main_first_block {
  margin-bottom: 150px;
}

.first_block_content {
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 90px;
  padding-top: 90px;
  flex-direction: row-reverse;
  text-transform: none;
}

.first_block_text_wrap {
  text-align: center;
}

.first_block_registr_form_row {
  position: relative;
}

.first_block_registr_form_text_row {
  position: relative;
}

.first_block_registr_form_text_fieldset {
  display: grid;
  grid-row-gap: 12px;
}


.first_block_registr_form_text_row.with_email_help {
  position: relative;
  border: 1px solid transparent;
  height: 48px;
  box-sizing: border-box;
}

.first_block_registr_form_emails_list_wrap {
  position: absolute;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  border: 1px solid #9F9F9F;
  border-top: none;
  z-index: 3;
  display: none;
  width: 396px;
  top: 95%;
  left: -0.8px;
  @media screen and (max-width: 641px) {
    width: 311px;
  }
  @media screen and (max-width: 370px) {
    width: 272px;
  }
}

.first_block_registr_form_text_row.with_email_help.opened {
  background: #F8F8F8;
  border: 1px solid #9F9F9F;
  border-radius: 5px;

  .first_block_registr_form_emails_list_wrap {
    display: block;
    background: #F8F8F8;
    border-radius: 0 0 5px 5px;
  }

  .first_block_registr_form_input:active,
  .first_block_registr_form_input:focus {
    border: 0;
  }
}

.first_block_registr_form_emails_list {
  max-height: 200px;
  overflow-y: auto;
  margin-right: 5px;
  padding-right: 2px;
  border-radius: 0 0 5px 5px;
}

.first_block_registr_form_emails_list::-webkit-scrollbar {
  width: 3px;
  background: $littleGreyLittleBlueColor;
  border-radius: 5px;
  margin-right: 3px;
}

.first_block_registr_form_emails_list::-webkit-scrollbar-thumb {
  background: $scrollbarThumbColor;
  border-radius: 7px;
}

.first_block_registr_form_emails_list_item {
  padding: 13px 16px;
  cursor: pointer;
  transition: .3s;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $greyColor;
  font-family: $mainFont;
}

.first_block_registr_form_emails_list_item:hover {
  background: #EFEFEF;
}

.first_block_registr_form_input {
  display: block;
  width: 100%;
  flex: 1;
  height: 48px;
  padding: 13px 16px;
  font-weight: 600;
  line-height: 22px;
  font-size: 16px;
  color: $InputSelectedTextColor;
  background: #F8F8F8;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: $mainFont;

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #9F9F9F;
    font-family: $mainFont;
  }

  &:focus,
  &:active {
    border: 1px solid #9F9F9F;
    color: $greyColor;
    font-family: $mainFont;
  }

  &:-webkit-autofill {
    font-weight: 600 !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-webkit-autofill::first-line {
    color: $InputSelectedTextColor;
    -webkit-text-fill-color: $InputSelectedTextColor;
    -webkit-box-shadow: 0 0 0 1000px #F8F8F8 inset;
    font-weight: 600 !important;
    font-family: $mainFont;
    font-size: 16px;

    line-height: 22px;
  }


  @media screen and (max-width: 641px) {
    font-size: 14px;
    line-height: 19px;

    &::placeholder,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-webkit-autofill::first-line {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &.error {
    border: 1px solid #9B3B3B;
    background: #FFEEEE;

    &::placeholder {
      color: #9B3B3B;
    }
  }

}
.first_block_registr_form_password {
  padding-right: 50px;
}

.first_block_registr_form_row_line {
  height: 1px;
  width: 100%;
  background-color: $defaultTextColorAlpha3;
  border-radius: 4px;
}

.first_block_registr_form_row_line.error {
  background-color: #EB5757;
}

.first_block_registr_form_label {
  position: absolute;
  left: 16px;
  bottom: 13px;
  font-size: 16px;
  color: #9F9F9F;
  line-height: 22px;
  pointer-events: none;
  transition: .3s;
}

.first_block_registr_form_dob_row {
  margin-top: 24px;
}

.first_block_registr_dob_title {
  font-weight: 600;
  color: $stormGreyColor;
  margin-bottom: 17px;
}

.first_block_registr_dob_row_wrap {
  position: relative;
}

.first_block_registr_dob_row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 12px;
}

.main_select_item_wrap {
  position: relative;
  height: 48px;
  background: #F8F8F8;
  border-radius: 5px;
  box-sizing: border-box;

  &.error {
    border: 1px solid #9B3B3B;
    background: #FFEEEE;

    & .main_select_item_head {
      color: #9B3B3B;
    }
  }
}

.main_select_item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  box-sizing: border-box;
}

.main_select_item_head {
  height: 48px;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  position: relative;
  color: #9F9F9F;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  @media screen and (max-width: 641px) {
    font-size: 14px;
    line-height: 19px;
  }
}

.main_select_item_head:after {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  background-image: url(../../../../../img/main_select_arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  transition: .3s;
}

.main_select_item.open .main_select_item_head:after {
  transform: translateY(-50%) rotate(180deg);
}

.main_select_item_head_line {
  width: calc(100% + 18px);
  height: 1px;
  background-color: $defaultTextColorAlpha3;
  box-shadow: 0 2px 10px rgba(84, 0, 114, 0.05);
  border-radius: 4px;
  margin-left: -18px;
}

.main_select_item_head_line.error {
  background-color: #FFEEEE;
}

.main_select_item_list {
  background: #F8F8F8;
  display: none;
  border-radius: 0 0 5px 5px;
  border-top: none;
  margin-top: -1px;
  padding: 0;
}

.main_select_item_list_inner {
  margin-right: 3px;
  max-height: 193px;
  overflow-y: auto;
  border-radius: 5px;
}

.main_select_item_list_inner::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 3px;
}

.main_select_item_list_inner::-webkit-scrollbar-thumb {
  background: $michiganSkyColor;
  border-radius: 3px;
}

.main_select_item_list input {
  display: none;
}

.main_select_item_list label {
  display: block;
  margin: 0;
  padding: 13px 16px;
  font-family: $mainFont;
  cursor: pointer;
  font-size: 16px;
  color: $greyColor;
  font-weight: 600;
  line-height: 22px;
  @media screen and (max-width: 641px) {
    font-size: 14px;
    line-height: 19px;
  }
}

.main_select_item_list label:hover {
  background: #EFEFEF;
}

.main_select_item.open {
  z-index: 3;
  background: #F8F8F8;
  border: 1px solid #9F9F9F;
  border-radius: 5px;

  .main_select_item_head {
    color: $InputSelectedTextColor;
    font-weight: bold;
  }
}

.main_select_item.open .main_select_item_list {
  display: block;
}


.main_select_item.selected .main_select_item_head {
  font-weight: 600;
  color: $InputSelectedTextColor;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:after {
    right: 16px;
  }
}

.first_block_registr_form_looking_row .main_select_item {
  .main_select_item_head {
    padding: 0;
    display: flex;
    align-items: center;

    &:after {
      right: 0;
    }
  }
}

.first_block_registr_form_looking_row {
  position: relative;

  &.selected {
    .main_select_item_head {
      font-weight: 600;
      color: $InputSelectedTextColor;
    }
  }
}

.first_block_registr_form_looking_row.open {
  border: 1px solid #9F9F9F;
  border-bottom: none;

  .main_select_item_wrap {
    position: relative;
  }

  .main_select_item_head {
    display: none;
  }

  .first_block_registr_form_looking_title {
    font-weight: 600;
    color: $InputSelectedTextColor;
    position: relative;
    z-index: 10;
  }

  .main_select_item_wrap {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 192px;
    margin-left: -0.8px;
    top: 88%;
    @media screen and (max-width: 641px) {
      width: 311px;
    }
    @media screen and (max-width: 370px) {
      width: 272px;
    }
  }

  .main_select_item_list_inner {
    margin-right: 0;
  }

  .main_select_item_head:after {
    transform: translateY(-50%) rotate(180deg);
  }

  .main_select_item {
    z-index: 3;
    background: #F8F8F8;
    border: 1px solid #9F9F9F;
    border-top: none;
    border-radius: 0 0 5px 5px;
    width: 100%;

    .main_select_item_head {
      color: $InputSelectedTextColor;
      font-weight: bold;
    }
  }

  .main_select_item .main_select_item_list {
    display: block;
  }
}

.first_block_registr_form_looking_row {
  display: flex;
  align-items: center;
  background: #F8F8F8;
  border-radius: 5px;
  padding: 16px;
  height: 48px;
  box-sizing: border-box;
  cursor: pointer;
}

.first_block_registr_form_looking_title {
  color: #9F9F9F;
  font-size: 16px;
  line-height: 22px;
  @media screen and (max-width: 641px) {
    font-size: 14px;
    line-height: 19px;
  }
}

.first_block_registr_form_looking_row .main_select_item_wrap {
  flex: 1;
  margin-left: 8px;
}

.first_block_registr_form_policy_row {
  display: flex;
  align-items: center;
  margin-top: 22px;
}

.first_block_registr_form_policy_row input {
  display: none;
}

.first_block_registr_form_policy_row label {
  display: block;
  width: 24px;
  height: 24px;
  min-width: 24px;
  border: 1.5px solid $InputSelectedTextColor;
  border-radius: 4px;
  flex: none;
  margin-right: 16px;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: 641px) {
    width: 16px;
    height: 16px;
    min-width: 16px;
    border: 1px solid #9F9F9F;
    border-radius: 4px;
  }
}

.first_block_registr_form_policy_row.error label {
  border: 1px solid #EB5757;
}

.first_block_registr_form_policy_row input:checked + label:after {
  content: '';
  display: block;
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 100%;
  background: $InputSelectedTextColor;
  mask: url('../../../../../img/arrow-checked.svg') center no-repeat;
  mask-size: 12px 8px;
  -webkit-mask: url('../../../../../img/arrow-checked.svg') center no-repeat;
  -webkit-mask-size: 12px 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 641px) {
    width: 8px;
    height: 8px;
    min-width: 8px;
    background-size: contain;
  }
}

.first_block_registr_form_policy_text {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media screen and (max-width: 641px) {
    font-size: 10px;
    line-height: 15px;
  }
}

.first_block_registr_form_policy_text div {
  display: inline;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  padding-right: 2px;
  @media screen and (max-width: 641px) {
    font-size: 11px;
    line-height: 16px;
  }

  &:hover {
    border-bottom: 1px solid $InputSelectedTextColor;
    background: $InputSelectedTextColor;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.first_block_registr_form_policy_text span {
  background: $greyColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.first_block_registr_form_find_btn {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  margin-top: 24px;
  cursor: pointer;
  font-family: $mainFont;
  position: relative;
  border-radius: 5px;
  background-image: $buttonBackgroundColor, linear-gradient(to right, $accentLeftColor, $accentRightColor);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;

  &:hover {
    background-image: linear-gradient(white, white), linear-gradient(to right, $accentLeftColor, $accentRightColor);

    .first_block_registr_form_find_btn_text {
      background: $buttonBackgroundColor;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-weight: 700;
    }

  }

  @media screen and (max-width: 641px) {
    font-size: 16px;
    line-height: 22px;
  }
}


.first_block_registr_form_find_btn_text {
  background: #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #FFFFFF;
  background-clip: text;
  text-fill-color: #FFFFFF;
  position: relative;
  z-index: 1;
}


.first_block_registr_signup_text {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: $stormGreyColor;
  margin: 0 20px;
}

.first_block_registr_signup_socials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.first_block_registr_signup_socials_item {
  margin: 0 7px;
}

.first_block_registr_form_tablet_social {
  display: none;
  align-items: center;
  align-self: center;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.01em;
  color: #474F5C;
}

.first_block_registr_form_tablet_social_item {
  margin-left: 10px;
  cursor: pointer;
}

.first_block_registr_form_tablet_social_item img {
  display: block;
}

.main_registration_form_bottom_btn {
  width: 230px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: .01em;
  color: #426076;
  border: 1px solid #426076;
  border-radius: 5px;
  cursor: pointer;
}

.main_registration_form_bottom_btn_img {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-right: 12px;
}

.main_registration_form_bottom_btn_img.img_ggl {
  background-image: url(../../../../../img/auth/new_google_login.svg);
}

.registr_form_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  @media screen and (max-width: 641px) {
    margin-top: 16px;
    font-size: 14px;
    line-height: 19px;
  }
}

.registr_form_footer_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $greyColor;
  margin-right: 16px;
  @media screen and (max-width: 641px) {
    font-size: 14px;
    line-height: 19px;
    margin-right: 12px;
  }
}

.registr_form_footer_btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
  font-family: $mainFont;
  cursor: pointer;

  @media screen and (max-width: 536px) {
    font-size: 14px;
    line-height: 19px;
  }
}

.registr_form_footer_btn:hover .registr_form_footer_btn_text {
  background: $InputSelectedTextColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.registr_form_footer_btn_text {
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
}


.first_block_registr_form {
  position: relative;
  z-index: 0;
}

.first_block_registr_form_text_column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
  @media screen and (max-width: 641px) {
    grid-row-gap: 12px;
    grid-template-columns: 1fr;
  }
}


.first_block_registr_password_show {
  width: 24px;
  height: 24px;
  border: 0;
  outline: 0;
  background: url('../../../../../img/password-show.svg') center no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.4);
  }

  &.active {
    background: url('../../../../../img/password-hide.svg') center no-repeat;
    background-size: contain;
  }

  @media screen and (max-width: 641px) {
    width: 18px;
    height: 18px;
  }

}