$modalBackgroundColor: #fff;

$inputColor: #1C2677;
$inputBackgroundColor: #F4F4F4;

$violetBlueColor: #2B5A64;

$buttonColor: #fff;
$buttonBackgroundColor: linear-gradient(90.44deg, #BE5485 0%, #B43972 100%);
$buttonModalBackgroundColor: linear-gradient(90.44deg, #BE5485 0%, #B43972 100%);
$buttonBackgroundColorHover: #fff;
$buttonBackgroundColorDisabled: linear-gradient(0deg, #19191926 0%, #19191926 100%); ;
$buttonTextColorDisabled: linear-gradient(0deg, #333333 0%, #333333 100%);

$profileButtonColor: linear-gradient(90.44deg, #47605C 0%, #47605C 100%);
$profileButtonActiveColor: #47605C;

$boxShadowGrey: rgba(197, 196, 208, 0.64);
$boxShadowPurple: rgba(84, 0, 114, 0.24);

$iconCrossSearchColor: #7a1640;
$iconResetPasswordBackgroundColor: #ffe2cc;
$iconResetPasswordFillColor: #d45c00;
$iconCoinsBalanceGradientColor: linear-gradient(90.44deg, #BE5485 0%, #B43972 100%);
$iconCoinsBalanceDefaultColor: #7a1640;
$iconLikeWomanProfileFillColor: #EB5757;
$iconAddPlusFillColor: #7a1640;
$iconAddPhotoFillColor: #7a1640;
$iconVideosFillColor: #7B1640;
$iconHandWaveFillColor: #7B1640;
$iconLetterFillColor: #7B1640;
$iconFilterFillColor: #7B1640;
$iconLikeVideoComponentFillColor: #7B1640;
$iconLetterVideoComponentFillColor: #7B1640;
$iconMoveFillColor: #7a1640;
$iconUploadAttachmentFillColor: #B43972;
$iconArrowLeftFillColor: #B43972;
$iconsMoreInfoChatHeaderFillColor: #BE5485;
$iconProfileFillColor: #7a1640;
$iconCrossCloseFillColor: #7a1640;
$iconReplyFillColor: #7a1640;

$supportFeedbackModalHeaderBgColor: rgba(190, 84, 133, 0.1);
$supportFeedbackRadioLabelColor: #7B1640;
$supportFeedbackRadioLabelColorChecked: #7B1640;
$supportFeedbackRadioLabelColorHover: #7B16404D;
$supportFeedbackRatingTextColor: #7B1640;
$supportFeedbackTitleColor: #B43972;
$supportFeedbackTextColor: #333333;
$supportFeedbackButtonBorderHover: double 1px transparent;

$mainFont: "Open Sans", sans-serif;
$greenColor: #1C9D53;
$whiteColor: #fff;
$greyColor: #4F4F4F;
$greyBlueColor: #426076;

$inputTextColor: #334B48;

$inputBorderNormal: #F1DDD2;
$inputBorderHover: #FF9E68;
$inputBorderActive: #FF9E68;

$selectBackgroundColor: #F8F0F4;
$selectBorderColor: #7b1640;
$selectBorderHoverColor: #BE5485;

$textareaBackgroundColor: #FFFFFF;
$textareaBackgroundHoverColor: #F5F7FA;
$textareaBackgroundActiveColor: #FFFFFF;
$textareaBorderColor: #F1DDD2;
$textareaBorderActiveColor: #B43972;
$textareaOutlineColor: #B439721a;

$messageRightBackground: #F8F8F8;
$messageLeftBackground: #F8F0F4;

$chatTextareaBg: #EBF2F7;

$attachTouchText: #BE5485;

$bluePinkColor: #BE5485;

$uploadTabsColor: #334B48;
$uploadTabsActiveColor: #BE5485;

$uploadTabsItemBg: #E7EEF4;

$uploadTabsButton: #B43972;

$mediaListTabActiveBg: #F8F0F4;
$mediaListTabActiveColor: #333;

$mediaListSwitcherActiveBg: #F8F0F4;

$searchBottomBtn: #BE5485;

$buttonHoverBg: #F8F8F8;
$buttonHoverBg2: #F8F0F4;
$buttonHoverBg3: #F8F0F4;

$lettersMenuIconColor: #47605C;

$videoButtonsColor: #BE5485;

$paymentBorderColor: rgba(68, 96, 119, 0.3);
$paymentBorderActiveColor: #798F8C;

$unreadLetter: #E8F1FA;

  //LOGIN MODAL
$colorLoginForgetButton: #EB5757;
$colorLoginBottom: rgba(0,0,0,.2);
$colorLoginBottomSpan: #474F5C;

//ERROR MODAL
$colorErrorTitle: #00317B;
$colorErrorTitleHref: #1952ab;

//MAIN PAGE
$buttonArrowBackground: rgba(240, 249, 255, 0.9);

//ICONS

$c1ProfileIco: url(../../img/c1-profile_btn.svg);

$c3StickerViolet: url(../../img/c3-msg-sticker-violet.svg);
$c3StickerBlue: url(../../img/c3-msg-sticker-blue.svg);

$chatHello: url(../../img/chat-hello.svg);

$profileEdit: url(../../img/c3ph-edit.svg);
$profileEditH: url(../../img/c3ph-edit-h.svg);

$profileCredits: url(../../img/c3ph-purchase.svg);
$profileCreditsH: url(../../img/c3ph-purchase-h.svg);

$profileGifts: url(../../img/c3ph-gift.svg);
$profileGiftsH: url(../../img/c3ph-gift-h.svg);

$profileContact: url(../../img/c3ph-contact.svg);
$profileContactH: url(../../img/c3ph-contact-h.svg);

$profileMeeting: url(../../img/c3ph-meet.svg);
$profileMeetingH: url(../../img/c3ph-meet-h.svg);

$profileBlock: url(../../img/block-list-p.svg);
$profileBlockH: url(../../img/block-list-p-h.svg);

$profileLogout: url(../../img/logout-p.svg);

$editProfileBtn: url(../../img/edit-profile-btn.svg);

$profileLetter: url(../img/c3wpt_mail.svg);
$profileFavs: url(../img/c3wpt_favs.svg);
$profileComplain: url(../img/c3wpt_compl.svg);
$profileGiftTop: url(../img/c3wpt_gift.svg);

$messageStatusRead: url(../../img/readed.svg);
$messageStatusSend: url(../../img/send.svg);

$paymentSelectedCheck: url(../../../../img/check-circle-full-violet.svg);

$npsRadioLabelColor: #7B1640;
$npsRadioLabelColorChecked: #7B1640;
$npsRadioLabelColorHover: #7B16404D;
$npsButtonBorderHover: double 1px transparent;
$ratingTextColor: #333333;
$npsInputBorderColor: #F1DDD2;
$npsCheckboxColor: #7B1640;
$npsContactsInputColor: #333333;
$npsTextAreaBackgroundActiveColor: #F5F7FA;
$npsModalTitleColor: #BE5485;

//POLICY
$policyTitleColor: #B43972;
$policyTextColor: #7B1640;
$policyButtonBg: #FFF3F8;
$policyLinksColor: #0000FF;
$policyLinksHoverColor: #0101D6;
$policyScrollColor: #CB7096;

@import 'theme';
