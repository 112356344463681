// COLORS
// ACCENT
// gradient colors
$accentLeftColor: #BE5485;
$accentRightColor: #B43972;

$accentLeftRightColor: #B43972;
$bleachColor: #f4f4f4;

// PRIMARY
$primaryColor: #7B1640;
$primary2Color: #7B1640;
$primaryLiteColor: #3f7298; // checkbox, player elements (play, progress, etc)
$primaryOrangeColor: #B43972;
$primarySearchButton: #fff;
$primarySearchButtonBorder: linear-gradient(90.5deg, $accentLeftColor 0%, $accentRightColor 100%);
$primarySearchButton2: $primaryColor;
$primarySearchButtonBorder2: $primaryColor;
$primarySearchButtonBackground2: #fff;
$primarySearchButton3: $searchBottomBtn;
$primarySearchButton3BackgroundHover: #fff;

// BACKGROUND
$background1Color: #e7eef4; // column 1, 2, 4 and other shit
$background2Color: #F8F0F4; // inbox messages
$background3Color: #F8F8F8; //?= active item background
$background4Color: #F8F8F8; // outcome messages
$background5Color: #F8F0F4; //= active input background color
$background6Color: #F8F0F4;
$backgroundOnlineColor: linear-gradient(90deg, #1C9D53 1.42%, rgba(28, 157, 83, 0.7) 99.86%);
$backgroundSearchItemShadowColor: rgba(197, 196, 208, 0.64);
$backgroundButtonColor: linear-gradient(90.5deg, $accentLeftColor 0%, $accentRightColor 100%);
$backgroundTopMenuButtonColor: #fff;
$background2TopMenuButtonColor: $primary2Color;
$backgroundSearchColor: linear-gradient(0deg, #EBEDFA 48.74%, rgba(235, 237, 250, 0.8) 59.89%, rgba(235, 237, 250, 0) 99.34%);

//LikeButton
$defaultLikeButtonColor: #7B1640;
$activeLikeButtonColor: #78687A;

//MENU
$policyHoverColor: #FFF;
$addCreditsBackgroundColor: #FFF;
$addCreditsHoverColor: $accentRightColor;
$submenuButtonColor: rgba(55, 78, 108, 0.7);
$backgroundButtonActiveColor: $primaryColor;
$buttonTitleActiveColor: #fff;
$backgroundButtonHoverColor: #B53C74;
$buttonTitleHoverColor: #FFF;
$buttonTitleColor: $primaryColor;
$smallActiveButtonColor: $primaryColor;
$newBadgeBg: #F8F0F4;
$newBadgeColor: #B53C74;
$newBadgeHoverBg: #F8F0F4;
$newBadgeHoverColor: #B53C74;
$newBadgeActiveBg: #F8F0F4;
$newBadgeActiveColor: #B53C74;
$buttonBorderPolicyColor: #B53C74;
$buttonTitlePolicyMenu: #B53C74;

// OTHERS
// mutable
$iconColor: #BE5485;
$counterAndErrorColor: #B43972; //= counter ёпта
$scrollbarThumbColor: #A8B7B5; // scrollbar thumb
$menuIconColor: #3664a1;
$onlineTitleColor: #fff;
$buttonBorderColor: $primaryOrangeColor;
$buttonBorder2Color: $primary2Color;
$buttonTitleTopMenu: $primaryOrangeColor;
$buttonTitle2TopMenu: #fff;

// SEARCH
$searchFilterTitle: $accentLeftColor;
$searchFilterSelectBg: $selectBackgroundColor;
$searchFilterSelectBorder: $selectBorderColor;
$searchFilterSelectColor: #333;
$searchBoxShadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
$searchBoxShadowBorder: rgba(10, 186, 181, 0);
$searchPlaceholder: #333;
$searchSelectBg: #EFF3F8;
$searchItemBtnBg: $backgroundButtonColor;
$searchItemBtnShadowHover: 0px 6px 12px rgba(189, 82, 131, 0.24);
$searchItemLikeBtnShadowHover: 0px 6px 12px rgba(189, 82, 131, 0.24);
$smallBtnShadowHover: 0 6px 12px rgba(189, 82, 131, 0.24);
$searchSayHelloBtnBorder: #446077;
$searchSayHelloBtnColor: #446077;
$searchProfileNameColor: #333333;

// NOTIFY
$notificationScrollbarBg: #F7F8FD;
$notificationMessageBg: #BE5485;
$notificationMessageText: #FFF;
$notificationFavouriteBg: #7B1640;
$notificationFavouriteText: #FFF;
$notificationLikedBg: #FFF;
$notificationLikedTitle: #474F5C;
$notificationLetterBg: $primaryColor;
$notificationDefaultText: #333;
$notificationOfflineColor: #E0E0E0;
$notificationFavouriteIcon: #f2c94c;
$notificationMatchBg: #F1C8DA;
$notificationMatchColor: #745E97;
$notifyItemSayHiButtonBackground: #FFF;
$notifyListShadow: linear-gradient(180deg, rgba(235, 237, 250, 0) 0%, #EBEDFA 100%);
$notifyListBottomBtn: #D5DAF3;
$notifyBigPhotoBg: #BE5485;
$notifyBigPhotoFavBg: #7B1640;
$notifyCounterBg: #EB5757;
$notifyCounterColor: #fff;
$notifyMainShadow: -6px 0px 12px rgba(47, 43, 99, 0);
$notifyItemClose: #33333399;
$notifyItemEachLikeHeart: #CC193B;
$notifyListBorder: #D5DAF3;
$notifyBlockBorder: #fff;
$notifyGirlItemBg: #fff;
$notifyGirlItemTitle: #474F5C;
$notifyGirlItemAppealText: #4336de;
$notifyGirlItemNoChatText: #4336de;
$notifyGirlItemNoChatMobText: #9B51E0;
$notifyGirlItemWantChatText: #00317B;
$notifyGirlItemOnlineText: #1C9D53;
$notifyGirlItemButtonText: #fff;
$notifyGirlItemButtonHiText: #001027;
$notifyGirlItemMatchDetails: #33333399;
$notifyMobDefaultBg: #fff;
$notifyMobHoverBg: #F1F1F1;
$notifyMatchChain: #F37070;
$notifyMobBottomBg: $background1Color;
$notifyItemEachLike: #F1C8DA;
$notifyItemEachLikeOnlineBorder: #F1C8DA;
$notifyItemEachLikeTitleColor: #745E97;
$notifyItemEachLikeTitle2Color: #333;
$supportTextColor: $accentRightColor;

// CHAT
$chatItemActiveBg: $primaryColor;
$chatItemActiveTitleColor: #FFFFFF;
$chatItemFromTitleColor: #6F0267;
$chatItemFromActiveTitleColor: #fff;
$chatHeaderOnlineBorder: #446077;
$chatItemBtnColor: $accentRightColor;
$chatNewBg: #EB5757;
$chatItemTitleColor: #474F5C;

// LIKE
$likeOnlineBg: linear-gradient(90deg, rgba(28, 157, 83, 0.7) 1.42%, #1C9D53 99.86%);
$likeOnlineMobBg: $likeOnlineBg;
$likeOnlineColor: #fff;
$likeSliderOnlineBg: linear-gradient(270.09deg, #27AE60 0.08%, rgba(39, 174, 96, 0.7) 99.93%);
$likeSliderOnlineColor: #fff;

// LETTER
$letterReadBg: green;

// PROFILE
$profileTitleColor: #446077;
$profileBorderSelect: $accentLeftColor;

// Header Main
$MainHeaderBg: #F8F0F4;
$MainStartBlockShadow: linear-gradient(0deg, #F8F0F4 13.85%, rgba(248, 240, 244, 0.78) 48.23%, rgba(248, 240, 244, 0) 100%);
$mainTitleColor: #7B1640;
$mainTextColor: #502956;
$mainStartBlockTextColor: rgba(123, 22, 64, 0.8);
$FooterBg: #7B1640;
$ButtonTransitional: unset;
$mainButtonBg: linear-gradient(90deg, #BE5485 -0.16%, #B43972 99.89%);
$buttonBackgroundColor: linear-gradient(90deg, #BE5485 -0.16%, #B43972 99.89%);
$mainButtonHoverBg: #7B1640;

// Main Form
$InputSelectedTextColor: #7B1640;

// Mobile chat
$mobileChatButtonNotificationSelectedBackground: #ebedfa;
$mobileChatEmptyTitleColor: #426076;
$mobileChatEmptyTextColor: #333333cc;
$mobileChatIconColor: #446077;
$mobileChatProfileNameColor: #474F5C;
$mobileChatMenuLinkColor: #00317B;
$mobileChatMenuBoxShadow: #0e048f3d;
$mobileChatMenuButtonBackground: #F7F7FE;
$mobileChatMessageBackgroundPrimary: #B6D1FE;
$mobileChatMessageBackgroundSecondary: #EEF1FE;
$mobileChatMessageTextColor: #333333;
$mobileChatMessageDateColor: #BDBDBD;
$mobileChatMessageGreetingBackground: #E8EEF8;
$mobileChatMessageGreetingColor: #5C6C8A;
$mobileChatFormMessageButtonBackground: linear-gradient(90.13deg, #00317B 0%, #720066 100%);
$mobileChatFormMessageFileUploadIconColor: #909FB8;
$mobileChatFormMessageTextareaBorderColor: #D6DFEE;
$mobileChatFormMessageTextColor: #4F4F4F;


// Letter chat
$mobileLetterMessageBookmarkedBackground: #d6daff;
$mobileLetterMessageBackgroundPrimary: #DBE5F7;
$mobileLetterMessageBackgroundSecondary: #F3F4FC;
$mobileAudioButtonBackground: #9C6EF7;
$mobileAudioNameTextColor: #4C3083;
$mobileAudioProgressBackgroundDefault: #9c6ef780;
$mobileAudioTimeColor: #33333399;
// Unsubscribe Page
$unsubscribeTitleColor: #7B1640;
$unsubscribeTextColor: #446077;
$unsubscribeInputBackground: #F8F0F4;
$unsubscribeInputPlaceholderColor: #7B164080;

// Modal Welcome
$modalWelcomeBackground: rgba(71, 47, 54, 0.5);
$modalWelcomeTitleColor: #7B1640;
$modalWelcomeTextColor: #502956;
$modalWelcomeTipsBackground: rgba(123, 22, 64, 0.05);
$modalWelcomeTipsColor: #7B1640;

$stepTitle: #00317B;
$stepNameColor: #01327C;
$stepNameSpanColor: $accentRightColor;
$stepDescriptionColor: rgba(51, 51, 51, 0.8);
$stepDescriptionSpanColor: #333;
$stepSkipButtonColor: #446077;
$stepNavigationDefault: rgba(28, 38, 119, 0.8);
$stepNavigationActive: #1c2677;
$stepNavigationPassed: rgba(28, 38, 119, 0.8);
$stepNextButtonColor: #446077;
$stepNextButtonHoverShadow: 0 4px 8px rgba(14, 4, 143, 0.24);
$stepMobButtonTextColor: $accentLeftColor;
$stepTutorialLeftMenuColor: #446077;
$stepHelloButtonBackground: $backgroundButtonColor;
$stepHelloButtonColor: #FFFFFF;
$stepHelloButtonBorder: 0;
$stepLikeButtonBorder: 1px solid #446077;
$stepLikeButtonColor: #446077;
$step6background: #EBEDFA;
$step6NotifyTitleColor: $uploadTabsActiveColor;

//How-to-start
$tutorialPopupBackground: #FFFFFF;
$tutorialPrimaryBackground: #EBEDFA;
$tutorialSecondaryBackground: #F4F4F4;
$tutorialButtonLightBackground: #FFFFFF;
$tutorialPrimaryIllustrationBackground: rgba(27, 42, 65, 0.20);
$tutorialMorePhotoBackground: rgba(255, 255, 255, .8);
$tutorialFilterItemBackground: #F8F0F4;
$tutorialMatchBoxBackground: $notificationMatchBg;
$tutorialMatchBoxTitleColor: $notificationMatchColor;
$tutorialMatchBoxTimeColor: rgba(116, 94, 151, 0.80);
$tutorialHelloBoxBackground: $background1Color;
$tutorialNotifySidebarBackground: $background1Color;
$tutorialNotifySidebarTextColor: #47605C;
$tutorialButtonLightColor: #FFFFFF;
$tutorialButtonGreyColor: #6F6F6F;
$tutorialCloseButtonGreyColor: #C2C2C2;
$tutorialPaginationItemColor: #F1F1F1;
$tutorialStepSecondaryColor: $primaryColor;
$tutorialCategoriesColor: rgba(55, 78, 108, 0.56);
$tutorialUserIdColor: rgba(68, 96, 119, 0.40);
$tutorialPrimaryGreenColor: #27AE60;
$tutorialChatProfileNameColor: #474F5C;
$tutorialChatMsgColor: #4F4F4F;
$tutorialChatMsgColor: #BDBDBD;
$tutorialFilterItemBorder: $primaryColor;
$tutorialFilterOnlineColor: #374e6c;
$tutorialMatchBoxUserColor: #A0A0A0;
$tutorialStepTextColor: rgba(51, 51, 51, 0.80);
$tutorialLightTextColor: #FFFFFF;
$tutorialStepTextSecondaryColor: #333333;
$tutorialSidebarDividerColor: $notifyListBottomBtn;
$tutorialHeartColor: #CC193B;
$accentLightColor: $accentRightColor;
$accentDarkColor: $accentLeftColor;
$tutorialMenuItemColor: $primaryColor;
$tutorialPrimaryBorderColor: #DFDFDF;
$tutorialSecondaryBorderColor: #BBBFDD;
$tutorialHeaderBorderColor: #DDDDDD;
$tutorialButtonBorderColor: #CDD3DA;
$tutorialDefaultIconColor: #8F9CAD;
$tutorialOnlineTextColor: #FFFFFF;
$tutorialButtonBackground: $backgroundButtonColor;
$tutorialOnlineBackground: $backgroundOnlineColor;
$tutorialLightGradient: linear-gradient(to top, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0));
$tutorialSliderListGradient: linear-gradient(-90deg, $tutorialSecondaryBackground 0%, rgba(244, 244, 244, 0.00) 100%);
$tutorialImageBackground: linear-gradient(178.98deg, rgba(235, 237, 250, 0) 0%, rgba(235, 237, 250, .63) 40%, rgba(235, 237, 250, .91) 61%, #ebedfa 100%);


//Forbidden page
$forbiddenPageBg: #F8F0F4;
$forbiddenTextColor: #7B1640;
$forbiddenSVGShadowColor: #F6CFE2;
$forbiddenSVGMapColor: #7B1640;
$forbiddenSVGMapBackground: #B65A80;

//Payment for first credit
$paymentBannerTitle: #7B1640;
$paymentBannerOffer: #B64077;
$paymentCorsTitle: #333333;
$paymentCorsItemTitle: #333333;
$paymentProsIconColor: #B64077;
$paymentProsIconBackground: #B6407714;
$paymentButtonBackground: #7B1640;
$paymentButtonColor: #FFF;
$paymentButtonBackgroundHover: #B64077;
$paymentButtonOutline: #B6407714;

@import '../common';
