@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import "@sitestyles";

.new_main_container {
  width: 100%;
  padding: 0 150px;
  @media screen and (max-width: 1400px) {
    padding: 0 32px;
  }
  @media screen and (max-width: 641px) {
    padding: 0 16px;
  }
  @media screen and (max-width: 370px) {
    padding: 0 8px;
  }
}

.main_page_wrap {
  background-color: $MainHeaderBg;
}

.main_title_h2 {
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  color: $mainTitleColor;
  margin: 0 auto 16px;

  @media screen and (max-width: 1140px) {
    font-size: 32px;
    line-height: 38px;
  }

  @media screen and (max-width: 641px) {
    font-size: 18px;
    line-height: 25px;

  }
}

.main_title_h3 {
  font-size: 18px;
  line-height: 156%;
  color: $mainTextColor;
  text-align: center;
  margin: 0 auto;
  max-width: 65%;
  @media screen and (max-width: 641px) {
    font-size: 14px;
    line-height: 150%;
    max-width: 100%;
  }
}

.popup_main_login {
  width: 520px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 8px;
  position: relative;
}

.popup_main_login_top {
  padding: 60px 60px 35px;
}

.popup_main_login_btn {
  display: block;
  width: 148px;
  height: 42px;
  line-height: 42px;
  background: linear-gradient(90.44deg, $accentLeftColor 0%, $accentRightColor 100%);
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  letter-spacing: .01em;
  color: #fff;
  margin: 32px auto 9px;
  cursor: pointer;
}

.popup_main_login_btn_forget {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #eb5757;
  cursor: pointer;
}

.popup_main_login_bottom {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px solid rgba(0, 0, 0, .2);
  height: 77px;
}

.popup_main_login_close {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  cursor: pointer;
}

.main_section {
  //margin-bottom: 150px;
}


.main_view_more_btn {
  width: 239px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  outline: 0;
  font-family: $mainFont;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;
  background-image: linear-gradient(white, white), linear-gradient(to right, $accentLeftColor, $accentRightColor);


  @media screen and (max-width: 600px) {
    width: 200px;
    font-size: 16px;
    line-height: 22px;
  }

  &:hover {
    background-image: $buttonBackgroundColor, linear-gradient(to right, $accentLeftColor, $accentRightColor);

    .main_view_more_btn_text {
      background: #FFFFFF;
      -webkit-background-clip: text;
      -webkit-text-fill-color: #FFFFFF;
      background-clip: text;
      text-fill-color: #FFFFFF;
      position: relative;
      z-index: 1;
    }
  }
}

.main_view_more_btn_text {
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
}