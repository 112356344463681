@import "@sitestyles";

.main_footer {
  background: $FooterBg;
  padding: 56px 0 34px;
  @media screen and (max-width: 641px) {
    padding: 16px 0;
  }
}

.footer_top {
  margin-bottom: 32px;
  @media screen and (max-width: 641px) {
    margin-bottom: 24px;
  }
}

.footer_top_row_1 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 60px;
  @media screen and (max-width: 641px) {
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.footer_col {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 641px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 25px;
    align-items: center;
    justify-content: center;
  }
}

.footer_info_images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 641px) {
    justify-content: center;
    align-items: center;
    width: 100%;
    column-gap: 24px;
  }
}

.footer_info_images img {
  margin-right: 30px;
  @media screen and (max-width: 641px) {
    max-height: 38px;
    max-width: 72px;
    margin-right: 0;
    width: auto;
    height: 38px;
    display: block;
  }
}

.footer_info_images img:last-child {
  margin-right: 0;
}

.footer_top_row_2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 641px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.footer_top_links {
  display: flex;
  align-items: center;
}

.footer_top_links div {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $whiteColor;
  text-decoration: none;
  margin-right: 85px;

}

.footer_top_links div:last-child {
  margin-right: 0;
}

.footer_top_links div:hover {
  text-decoration: underline;
}

.footer_top_social {
  display: flex;
  align-items: center;

  @media screen and (max-width: 641px) {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 24px;
  }


  div {
    margin-left: 40px;
    @media screen and (max-width: 641px) {
      margin-left: 0;
    }

    a {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      transition: $ButtonTransitional;

      @media screen and (max-width: 641px) {

      }

      &:hover {
        opacity: 0.6;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.footer_bottom {
  padding-top: 14px;
  border-top: 1px solid $whiteColor;
  @media screen and (max-width: 641px) {
    padding-top: 20px;
  }
}

.footer_logo img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  
  @media screen and (max-width: 1140px) {
    height: 48px;
    width: auto;
  }
}

.footer_bottom_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  @media screen and (max-width: 1600px) {
    margin-bottom: 16px;
    flex-direction: column-reverse;
  }
}

.main_footer_copyright {
  max-width: 340px;
  color: $whiteColor;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  .age_restriction {
    margin-top: 10px;
    font-size: 8px;
  }

  small {
    display: block;
    font-size: 9px;
  }

  @media screen and (max-width: 641px) {
    max-width: 100%;
    text-align: center;
  }
}

.footer_bottom_links {
  column-gap: 36px;
  row-gap: 24px;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 641px) {
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

.footer_bottom_links div {
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: $whiteColor;
  display: inline-block;
  transition: $ButtonTransitional;

  @media screen and (max-width: 1600px) {
    text-align: left;
  }

  @media screen and (max-width: 641px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
  }

  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
  }
}

.footer_bottom_disclaimer {
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.05em;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.7);

  @media screen and (max-width: 641px) {
    font-size: 10px;
    line-height: 14px;
  }
}