@import "@sitestyles";

.main_header_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  @media screen and (max-width: 1140px) {
    background: $MainHeaderBg;
  }
}

.main_header_wrap.scroll:before {
  display: none;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.main_header_wrap.scroll {
  background: $MainHeaderBg;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);

  .main_header_logo_img_general {
    display: block;
  }

  .main_header_logo_img_light {
    display: none;
  }
}

.main_header {
  display: flex;
  align-items: center;
  height: 80px;
  padding: 16px 0;
  box-sizing: border-box;
  @media screen and (max-width: 1140px) {
    height: 64px;
    padding: 12px 0;
  }
}

.main_header_left {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
}

.main_header_left_item {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $primaryColor;
  cursor: pointer;
}

.main_header_left_item:first-child {
  margin-right: 70px;
}

.main_header_logo {
  flex: 1;
  position: relative;
}

.main_header_logo_img_light {
  display: block;
  @media screen and (max-width: 1140px) {
    display: none;
  }
}

.main_header_logo_img_general {
  display: none;
  @media screen and (max-width: 1140px) {
    display: block;
  }
}

.main_header_logo img {
  margin: 0 auto;
  height: 48px;
  width: auto;
  @media screen and (max-width: 1140px) {
    height: 36px;
  }
}

.main_header_login {
  flex: 1;
  display: flex;
  position: relative;
}

.first_block_content_wrap_2 {
  position: relative;
}

.first_block_content_wrap {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: relative;
  @media screen and (max-width: 1140px) {
    flex-direction: column;

  }
}

.first_block_text_wrap {
  text-align: center;
  align-self: flex-end;
}

.first_block_registr_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;
}

.first_block_registr_form_row {
  position: relative;
}

.first_block_registr_form_input {
  display: block;
  width: 100%;
  height: 36px;
  padding-left: 7px;
  font-weight: 600;
  font-size: 16px;
  color: $unknownSenSmBLPaAColor;
}
.first_block_registr_form_password {
  padding-right: 50px;
}

.first_block_registr_form_row_line {
  height: 1px;
  width: 100%;
  background-color: $defaultTextColorAlpha3;
  border-radius: 4px;
}

.first_block_registr_form_label {
  position: absolute;
  left: 7px;
  bottom: 10px;
  font-size: 16px;
  color: $darkerIndigoTemplateColor;
  pointer-events: none;
  transition: .3s;
}

.first_block_registr_form_input:focus ~ .first_block_registr_form_label,
.first_block_registr_form_input:not(:placeholder-shown) ~ .first_block_registr_form_label,
.first_block_registr_form_input:-webkit-autofill ~ .first_block_registr_form_label {
  bottom: 41px;
  left: 0;
  font-size: 11px;
  color: $primaryColor;
}

.first_block_registr_form_dob_row {
  margin-top: 30px;
}

.first_block_registr_dob_title {
  font-weight: 600;
  color: $stormGreyColor;
  margin-bottom: 17px;
}

.first_block_registr_dob_row {
  display: flex;
  justify-content: space-between;
}

.main_select_item_wrap {
  position: relative;
  width: 30%;
  height: 42px;
}

.first_block_registr_dob_row .main_select_item_wrap:nth-child(2) {
  width: calc(40% - 16px);
  margin: 0 8px;
}

.main_select_item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}

.main_select_item_head {
  height: 42px;
  line-height: 40px;
  padding-left: 18px;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  color: $darkerIndigoTemplateColor;
}

.main_select_item_head:after {
  content: '';
  display: block;
  width: 11px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 19px;
  transform: translateY(-50%);
  background-image: url(../../../../img/auth/main_select_arrow.svg);
  background-repeat: no-repeat;
  transition: .3s;
}

.main_select_item.open .main_select_item_head:after {
  transform: translateY(-50%) rotate(180deg);
}

.main_select_item_head_line {
  width: calc(100% + 18px);
  height: 1px;
  background-color: $defaultTextColorAlpha3;
  box-shadow: 0px 2px 10px rgba(84, 0, 114, 0.05);
  border-radius: 4px;
  margin-left: -18px;
}

.main_select_item_list {
  background-color: $whiteColor;
  display: none;
  border: 1px solid $silverColor;
  border-radius: 0px 0px 10px 10px;
  border-top: none;
  margin-top: -1px;
  padding: 5px 0;
}

.main_select_item_list_inner {
  margin-right: 3px;
  max-height: 172px;
  overflow-y: auto;
}

.main_select_item_list_inner::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 3px;
}

.main_select_item_list_inner::-webkit-scrollbar-thumb {
  background: $michiganSkyColor;
  border-radius: 3px;
}

.main_select_item_list input {
  display: none;
}

.main_select_item_list label {
  display: block;
  margin: 0 5px;
  padding: 13px 13px 11px;
  cursor: pointer;
  font-size: 16px;
  color: $darkerIndigoTemplateColor;
}

.main_select_item_list label:hover {
  background: linear-gradient(0deg, $yourPaleColor, $yourPaleColor), $whiteColorAlpha8;
  border-radius: 5px;
  font-weight: 600;
  color: $primaryColor;
}

.main_select_item.open {
  z-index: 3;
}

.main_select_item.open .main_select_item_list {
  display: block;
}

.main_select_item.selected .main_select_item_head {
  font-weight: 600;
  color: $unknownSenSmBLPaAColor;
}

.first_block_registr_signup_hr {
  display: flex;
  align-items: center;
  margin-top: 29px;
}

.first_block_registr_signup_line {
  height: 1px;
  flex: 1;
  background-color: $greyColorBorderAlpha2;
}

.first_block_registr_signup_text {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: $stormGreyColor;
  margin: 0 20px;
}

.first_block_registr_signup_socials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.first_block_registr_signup_socials_item {
  margin: 0 7px;
}

.first_block_registr_form_tablet_social {
  display: none;
  align-items: center;
  align-self: center;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.01em;
  color: #474F5C;
}

.first_block_registr_form_tablet_social_item {
  margin-left: 10px;
  cursor: pointer;
}

.first_block_registr_form_tablet_social_item img {
  display: block;
}


.main_header_left {
  display: none;
}

.main_header_logo img {
  margin-left: 0;
}

.header_buttons {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  align-items: center;

  @media screen and (max-width: 350px) {
    grid-column-gap: 8px;
  }
}


.header_button_login {
  width: 142px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  font-family: $mainFont;
  border-radius: 4px;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;
  background-image: $buttonBackgroundColor, linear-gradient(to right, $accentLeftColor, $accentRightColor);

  &:before {
    content: '';
    display: block;
    background: #FFFFFF;
    -webkit-mask: url('../../../../../src/img/login-btn-white.svg') center no-repeat;
    mask: url('../../../../../src/img/login-btn-white.svg') center no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    min-width: 24px;
  }

  &:hover {
    background-image: linear-gradient(white, white), linear-gradient(to right, $accentLeftColor, $accentRightColor);

    .header_button_login_text {
      background: $buttonBackgroundColor;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-weight: 700;
    }

    &:before {
      background: $buttonBackgroundColor;
    }
  }

  @media screen and (max-width: 1140px) {
    height: 40px;
    font-size: 14px;
    line-height: 19px;
    padding: 0;
    width: 104px;
    &:before {
      margin-right: 8px;
    }
  }
}

.header_button_login_text {
  background: #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #FFFFFF;
  background-clip: text;
  text-fill-color: #FFFFFF;
  position: relative;
  z-index: 1;
}

.header_button_support {
  width: 70px;
  height: 70px;
  background: #FFFFFF;
  opacity: 0.7;
  border: 2px solid #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0;
  bottom: 14px;
  right: 14px;
  z-index: 10000;
  position: fixed;
  transition: all 0.3s ease;
  animation: pulse-animation 4s infinite;
  animation-delay: 1s;

  @media screen and (max-width: 1400px) {
    width: 65px;
    height: 65px;
  }

  @media screen and (max-width: 600px) {
    width: 56px;
    height: 56px;
    right: 6px;
  }

  &:before {
    content: '';
    display: block;
    background: #474F5C;
    width: 26px;
    height: 26px;
    border: 0;
    mask: url('../../../../img/main-support.svg') center no-repeat;
    mask-size: contain;
    -webkit-mask: url('../../../../img/main-support.svg') center no-repeat;
    -webkit-mask-size: contain;
    transition: all 0.3s ease;
    @media screen and (max-width: 600px) {
      height: 24px;
      width: 24px;
    }
  }

  &:hover {
    opacity: 1;
    box-shadow: 0 17px 40px rgba(0, 0, 0, 0.1);

    &:before {
      width: 32px;
      height: 32px;
    }
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    opacity: 0.7;
  }
  100% {
    box-shadow: 0 17px 640px rgba(0, 0, 0, 0.1);
    opacity: 1;

  }
}